import React, { useEffect, Fragment } from "react"
import BelowFoldCards from "../../general/belowFoldCards"
import CTA from "../../general/cta"
import EntryFold from "../../general/entryFold"
import Card from "../../../../../assets/img/card-icon.inline.svg"
import Zero from "../../../../../assets/img/zero-card-icon.inline.svg"
import SaveIcon from "../../../../../assets/img/save-icon.inline.svg"
import { scrollToElement } from "../../../../utility/utils"
import ImageTextLeft from "../../general/imageTextLeft"
import ImageTextRight from "../../general/imageTextRight"
import SpendSaveIllustration from "../../../../../assets/img/spendSaveIllustration.inline.svg"
import SaveFrequencyIllustration from "../../../../../assets/img/saveFrequency.inline.svg"
import FixedStashIllustration from "../../../../../assets/img/fixedStash.inline.svg"
import { StaticImage } from "gatsby-plugin-image"
import MoreForYouComponent from "../../general/more-for-you"

import InvestmentIcon from '../../../../../assets/img/more-for-you/kuda-investimet-icon.inline.svg'
import TransferIcon from '../../../../../assets/img/more-for-you/kuda-transfer.inline.svg'
import BorrowIcon from '../../../../../assets/img/more-for-you/kuda-borrow-icon.inline.svg'

const entryContent = {
  title: "Save more automatically.",
  subtitle:
    "Saving money can be hard. We’ve been there. But there’s an easier way you’ll love. It begins with downloading Kuda.",
  illustration: <StaticImage src={`../../../../../assets/img/kuda-new-save.png`} placeholder="blurred" alt="kuda Microfinance Bank Savings, Spend, Budgeting." loading="eager" />

}

const kudaTopFeatures = [
  {
    icon: <Card />,
    text: "Get up to 12% annual interest on Fixed Savings.",
  },
  {
    icon: <SaveIcon />,
    text: "Save every time you spend from your account with Spend+Save.",
  },
  {
    icon: <Zero />,
    text: "Spend nothing on ridiculous bank charges.",
  },
]

const debit = {
  payTitle: "Effortless saving is a real thing.",
  subtitle:
    "You can’t avoid spending. That’s how you pay for your needs. But we can help you put money away every time you pay for something. Just set a percentage to save and watch your money grow with Spend+Save",
  url: "https://help.kuda.com/support/solutions/articles/73000560724-use-spend-save",
  name: "See how Spend + Save works",
  payIllustration: <SpendSaveIllustration />,
}

const payBills = {
  payTitle: "Meet your money goals every time.",
  subtitle:
    "Save daily, weekly or monthly, that’s up to you. Whichever way, our Save Frequently Pocket will make it a breeze to put money away regularly.",
  url: "https://help.kuda.com/support/solutions/articles/73000616464-create-a-save-frequently-pocket",
  name: "Learn more about a Save Frequently Pocket",
  payIllustration: <SaveFrequencyIllustration />,
}

const paymentLink = {
  payTitle: "Stash your money and watch it grow.",
  subtitle:
    "Money grows wings fast. Use our Fixed Savings plan to lock down lump sums and get up to 12% annual interest.",
  url: "https://help.kuda.com/support/solutions/articles/73000560720-create-a-fixed-savings-plan",
  name: "Learn more about Fixed Savings",
  payIllustration: <FixedStashIllustration />,
}

const moreForYou = [
  {
    icon: <InvestmentIcon />,
    title: "Investment",
    subText: `Invest in US stocks with your naira, earn dividends, and get real-time updates on your Kuda app.`,
    linkTo: `/en-ng/investments/`
  },
  {
    icon: <TransferIcon />,
    title: "Transfer & Spend",
    subText: "Send money for free to any Nigerian account with 25 free transfers every month.",
    linkTo: `/en-ng/spend/`
  },
  {
    icon: <BorrowIcon />,
    title: "Loans",
    subText: `Get up to ₦150,000 in your Kuda account easily and repay in convenient instalments.`,
    linkTo: `/en-ng/personal-loan/`
  },
 
]
const Save = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <EntryFold
        ctaClass={"desktop-only"}
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        illustration={entryContent.illustration}
      />
      <BelowFoldCards topFeatures={kudaTopFeatures} />
      <ImageTextRight
        title={debit.payTitle}
        subtitle={debit.subtitle}
        illustration={debit.payIllustration}
        name={debit.name}
        url={debit.url}
        isExternal={true}
      />
      <ImageTextLeft
        title={payBills.payTitle}
        subtitle={payBills.subtitle}
        illustration={payBills.payIllustration}
        name={payBills.name}
        url={payBills.url}
        isExternal={true}
      />
      <ImageTextRight
        title={paymentLink.payTitle}
        subtitle={paymentLink.subtitle}
        illustration={paymentLink.payIllustration}
        name={paymentLink.name}
        url={paymentLink.url}
        isExternal={true}
      />
      <MoreForYouComponent moreForYou={moreForYou}/>
      <CTA />
    </Fragment>
  )
}

export default Save
