import React from "react"
import Save from "../../components/body/pages/en-ng/save"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const FAQSPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/save/"}
      title="Kuda | The Money App for Africans | Open an Account in Minutes"
      description="Saving money can be hard. We’ve been there. But there’s an easier way you’ll love. Kuda's automatic savings & investments make your money go further."
    />
    <Save />
  </Layout>
)

export default FAQSPage
